<template>
  <div>
    <div v-if="error" class="pt-3 text-left">
      <div class="alert alert-danger" role="alert">
        <p>Es ist ein Fehler aufgetreten.</p>
        <p class="fs-7">{{ error }}</p>
      </div>
    </div>
    <div v-else>
      <div class="pt-3 text-left">
        <p><strong>Mindestanforderungen für das neue Passwort:</strong></p>
        <ul>
          <li>Mindestens eine 'gute' Passwortstärke</li>
          <li v-for="guideline in guidelines" :key="guideline.policy">
            {{ mapGuideline( guideline ) }}
          </li>
        </ul>
      </div>
      <div class="pt-3 text-left">
        <p><strong>Bitte beachten Sie zu Ihrer eigenen Sicherheit:</strong></p>
        <ul>
          <li>
            Verwenden Sie keine Namen, auch nicht mit Groß-/Kleinschreibung oder rückwärts, und keine Wörter, die in Wörterbüchern zu finden sind.
          </li>
          <li>
            Verwenden Sie keine Tastaturfolgen (z. B. asdfgh), Zahlenfolgen (z. B. 123456), Alphabetfolgen (z. B. abcdef) oder zyklische Passwörter (muster01, Muster02 etc.).
          </li>
          <li>
            Nutzen Sie dasselbe Passwort nicht für mehrere Zwecke.
          </li>
          <li>
            Verwenden Sie am Besten automatisch erzeugte (zufällige) Passwörter und einen Passwort-Manager.
          </li>
          <li>
            Ändern Sie Ihr Passwort regelmäßig, alle 360 Tage, insbesondere bei (Verdacht auf) Kenntnisnahme des Passwortes durch einen unberechtigten Dritten.
          </li>
          <li>
            Weitere Informationen finden Sie beim <a href="https://www.bsi.bund.de/DE/Themen/Verbraucherinnen-und-Verbraucher/Informationen-und-Empfehlungen/Cyber-Sicherheitsempfehlungen/Accountschutz/Sichere-Passwoerter-erstellen/sichere-passwoerter-erstellen_node.html">Bundesamt für Sicherheit in der Informationstechnik</a>.
          </li>
        </ul>
    
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PasswordGuidelinesInfo",
  data: () => ({
    error: null,
    guidelines: null,
  }),
  methods: {
    mapGuideline( guideline ) {
      if ( 'NotRecentPasswordPolicy' === guideline.policy ) {
        return 'Keine Wiederholung der letzten ' + guideline.parameters.threshold + ' Passwörter'
      }
      else {
        return 'Mindestens ' + guideline.parameters.min + ' ' + this.mapPolicy( guideline.policy, guideline.parameters.min );
      }
    },
    mapPolicy( policy, count ) {
      if ( 'ContainsDigitsPolicy' === policy ) {
        return 'Ziffer';
      } else if ( 'ContainsLowercasePolicy' === policy ) {
        return count > 1 ? 'Kleinbuchstaben' : 'Kleinbuchstabe';
      } else if ( 'ContainsUppercasePolicy' === policy ) {
        return count > 1 ? 'Großbuchstaben' : 'Großbuchstabe';
      } else if ( 'ContainsSpecialCharactersPolicy' === policy ) {
        return 'Sonderzeichen';
      } else if ( 'MinimumLengthPolicy' === policy ) {
        return 'Zeichen lang';
      } else {
        return policy;
      }
    },
    getGuidelines() {
      axios.get( this.$store.getters.getAllEnvis.API_PASSWORD_GUIDLINES.replace('{subsite}', this.$store.getters["getSubsite"]) )
          .then( response => {
            this.guidelines = response.data;
            this.guidelines.sort(function(a, b){
              return a.policy < b.policy;
            });
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })
    },
  },
  created() {
    this.getGuidelines();
  }
}
</script>

<style scoped>

</style>